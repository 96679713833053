@import '@/shared/scss-utils/breakpoints';
@import '@/shared/scss-utils/functions';

.root {
  position: relative;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  display: flex;
  align-items: center;
  gap: 80px;
  padding-top: 72px;
  padding-bottom: 72px;

  @include laptop {
    padding-top: 108px;
    padding-bottom: 99px;
  }

  @include tablet {
    gap: 50px;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 50px;
  }

  @include mobile {
    gap: 28px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.title {
  line-height: 100%;
  padding: 50px 0;
  max-width: 491px;

  @include laptop {
    max-width: 425px;
    font-size: 32px;
    line-height: 35px;
  }

  @include tablet {
    max-width: 100%;
    padding: 0;
  }

  @include mobile {
    font-size: 24px;
    line-height: 27px;
  }
}

.cards {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  flex-grow: 1;

  @include laptop {
    gap: 32px;
  }

  @include tablet {
    width: 100%;
    gap: 20px;
  }

  @include mobile {
    flex-direction: column;
  }
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  padding: 32px 25px;
  background-image: url('../../../public/images/games/bg.png');
  background-repeat: no-repeat;
  background-size: cover;

  @include laptop {
    padding: 24px;
  }

  @include mobile {
    padding: 22px 24px;
  }

  &:before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgb(84, 99, 151) 0%,
      rgb(22, 46, 64) 100%
    );
  }
}

.image {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 57.5%;
  margin-bottom: 24px;
  border-radius: 10px;
  overflow: hidden;

  @include mobile {
    padding-bottom: 57%;
    max-width: calc(100% - 4px);
    margin: 0 auto 24px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ball1 {
  display: none;

  @include laptop {
    display: block;
    transform: rotate(-30deg) translateY(var(--transform));
    @include responsiveProperty(width, 112, true);
    @include responsiveProperty(height, 112, true);
    @include responsiveProperty(bottom, 54, true);
    @include responsiveProperty(left, 600, true);
  }

  @include tablet {
    display: none;
  }

  @include mobile {
    display: block;
    left: auto;
    transform: rotate(-35deg) translateY(var(--transform));
    @include responsiveProperty(width, 425, true);
    @include responsiveProperty(height, 425, true);
    @include responsiveProperty(bottom, -1145, true);
    @include responsiveProperty(right, -226, true);
  }
}
