@import '@/shared/scss-utils/breakpoints';

.typography {
  color: var(--color-white);
}

.h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;

  @include laptop {
    font-size: 40px;
  }

  @include mobile {
    font-size: 28px;
  }
}

.h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;

  @include laptop {
    font-size: 24px;
  }

  @include mobile {
    font-size: 20px;
  }
}

.h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 110%;

  @include laptop {
    font-size: 24px;
  }

  @include mobile {
    font-size: 18px;
  }
}

.p {
  font-weight: 400;
  font-size: 24px;
  line-height: 129%;
  color: var(--text-secondary);

  @include laptop {
    font-size: 18px;
  }
}

.typography span,
.yellowGradient {
  background: linear-gradient(97deg, #febd17 0%, #ffec43 48.96%, #ffe143 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
