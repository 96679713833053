@import '@/shared/scss-utils/breakpoints';
@import '@/shared/scss-utils/functions';

.root {
  position: relative;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  --container-width: 1420px;
  padding-top: 73px;
  padding-bottom: 73px;

  @include laptop {
    --container-width: 1120px;
    padding-top: 36px;
    padding-bottom: 36px;
  }

  @include mobile {
    padding-top: 40px;
    padding-bottom: 0;
  }
}

.title {
  max-width: 1000px;
  margin: 0 auto 80px;
  text-align: center;

  @include laptop {
    max-width: 700px;
    font-size: 32px;
    margin-bottom: 59px;
  }

  @include mobile {
    font-size: 24px;
    margin-bottom: 29px;
  }
}

.benefits {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 40px;

  @include tablet {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    row-gap: 40px;
    justify-content: center;
  }

  @include ipad {
    row-gap: 32px;
  }

  @include mobile {
    padding: 0 3px;
  }
}

.benefit {
  &:last-child {
    display: grid;
    align-items: center;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    grid-column: 3 span;

    @include tablet {
      display: flex;
      flex-direction: column;
    }

    @include mobile {
      align-items: flex-start;
    }

    .image {
      width: 100%;
      grid-column: 2 span;
      margin-bottom: 0;

      @include mobile {
        max-width: 100%;
      }
    }
  }

  @include tablet {
    width: 50%;
  }

  @include ipad {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @include mobile {
    align-items: flex-start;
  }
}

.image {
  display: block;
  width: 100%;
  margin-bottom: 50px;

  @include laptop {
    width: 80%;
    margin-bottom: 20px;
  }

  @include ipad {
    max-width: 500px;
  }

  @include mobile {
    margin-left: 2px;
    max-width: 260px;
    margin-bottom: 24px;
  }
}

.subtitle {
  margin-bottom: 22px;

  @include laptop {
    margin-bottom: 15px;
  }

  @include mobile {
    margin-bottom: 17px;
  }
}

.list {
  display: flex;
  gap: 16px;
  flex-direction: column;

  @include mobile {
    gap: 12px;
  }
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  line-height: 130%;

  @include laptop {
    font-weight: 400;
  }

  &:before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #fed748;
  }
}

.btn {
  @include laptop {
    max-width: 240px;
  }

  @include mobile {
    max-width: 100%;
  }
}

.ball1 {
  transform: rotate(-31deg) translateY(var(--transform));
  @include responsiveProperty(width, 83.69);
  @include responsiveProperty(height, 83.69);
  @include responsiveProperty(top, -119);
  @include responsiveProperty(right, 432);

  @include laptop {
    display: none;
  }

  @include ipad {
    display: block;
    @include responsiveProperty(right, -520, true);
    @include responsiveProperty(top, 970, true);
    @include responsiveProperty(width, 610, true);
    @include responsiveProperty(height, 610, true);
  }
}

.ball2 {
  transform: rotate(-30deg) translateY(var(--transform));
  @include responsiveProperty(width, 238.53);
  @include responsiveProperty(height, 238.53);
  @include responsiveProperty(top, -76);
  @include responsiveProperty(left, 155);

  @include laptop {
    display: none;
  }

  @include ipad {
    display: block;
    left: auto;
    @include responsiveProperty(right, -550, true);
    @include responsiveProperty(top, 2760, true);
    @include responsiveProperty(width, 680, true);
    @include responsiveProperty(height, 680, true);
  }
}

.ball3 {
  transform: rotate(24deg) translateY(var(--transform));
  opacity: 0.2;
  @include responsiveProperty(width, 238.53);
  @include responsiveProperty(height, 238.53);
  @include responsiveProperty(top, 338);
  @include responsiveProperty(left, -66);

  @include laptop {
    display: none;
  }

  @include ipad {
    transform: rotate(-24deg) translateY(var(--transform));
    display: block;
    left: auto;
    opacity: 1;
    top: auto;
    @include responsiveProperty(right, -350, true);
    @include responsiveProperty(top, 5400, true);
    @include responsiveProperty(width, 500, true);
    @include responsiveProperty(height, 500, true);
  }
}

.ball4 {
  transform: rotate(-32deg) translateY(var(--transform));
  @include responsiveProperty(width, 83.69);
  @include responsiveProperty(height, 83.69);
  @include responsiveProperty(bottom, 411);
  @include responsiveProperty(left, 82);

  @include laptop {
    left: auto;
    @include responsiveProperty(width, 107, true);
    @include responsiveProperty(height, 107, true);
    @include responsiveProperty(bottom, 566, true);
    @include responsiveProperty(right, -59, true);
  }

  @include ipad {
    display: none;
  }
}

.ball5 {
  transform: rotate(25deg) translateY(var(--transform));
  @include responsiveProperty(width, 175.85);
  @include responsiveProperty(height, 175.85);
  @include responsiveProperty(bottom, -11);
  @include responsiveProperty(left, 137);

  @include laptop {
    transform: rotate(-25deg) translateY(var(--transform));
    @include responsiveProperty(width, 165, true);
    @include responsiveProperty(height, 165, true);
    @include responsiveProperty(bottom, -78, true);
    @include responsiveProperty(left, 138, true);
  }

  @include ipad {
    display: none;
  }
}

.ball6 {
  transform: rotate(24.5deg) translateY(var(--transform));
  @include responsiveProperty(width, 238);
  @include responsiveProperty(height, 238);
  @include responsiveProperty(bottom, -43);
  @include responsiveProperty(right, 255);

  @include laptop {
    @include responsiveProperty(width, 325, true);
    @include responsiveProperty(height, 325, true);
    @include responsiveProperty(bottom, -185, true);
    @include responsiveProperty(right, 7, true);
    opacity: 0.2;
  }

  @include ipad {
    display: none;
  }
}
