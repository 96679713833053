@import '@/shared/scss-utils/functions';
@import '@/shared/scss-utils/breakpoints';

.root {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  @media (min-width: 1281px) {
    @include responsiveProperty(min-height, 997);
  }
}

.container {
  padding-top: 72px;
  padding-bottom: 131px;

  @media (max-width: 1280px) {
    padding-bottom: 72px;
  }

  @include ipad {
    display: flex;
    flex-direction: column-reverse;
  }

  @include mobile {
    padding-top: 79.5px;
    padding-bottom: 79.5px;
  }
}

.left {
  max-width: 52%;
  padding-top: 33.5px;

  @include laptop {
    padding-top: 160px;
  }

  @include tablet {
    padding-top: 80px;
    max-width: 45%;
  }

  @include ipad {
    padding-top: 44px;
    max-width: 100%;
  }
}

.imageContainer {
  @include ipad {
    width: calc(100% + 20px);
  }

  @include mobile {
    width: calc(100% + 75px);
  }
}

.image {
  position: absolute;
  top: 72px;
  right: 0;
  width: 47%;

  @include laptop {
    right: -24px;
    top: 108px;
  }

  @include tablet {
    width: 55%;
  }

  @include ipad {
    position: static;
    width: 100%;
  }
}

.title {
  margin-bottom: 25px;

  @include laptop {
    font-size: 32px;
    margin-bottom: 12px;
  }

  @include ipad {
    max-width: 95%;
  }

  @include mobile {
    font-size: 24px;
  }
}

.subtitle {
  margin-bottom: 24px;

  @include mobile {
    margin-bottom: 11px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 73px;

  @include laptop {
    margin-bottom: 34px;
    gap: 21px;
  }
}

.badgeContainer {
  position: relative;
  display: block;
  @include responsiveProperty(width, 600);
  @include responsiveProperty(height, 160);
  border-radius: 24px;
  overflow: hidden;
  margin-left: 4px;
  transform: rotate(-3.5deg);
  box-shadow:
    inset 4px 4px 4px 0 rgba(60, 92, 80, 0.51),
    inset -4px -4px 4px 0 rgba(60, 92, 80, 0.48),
    0 26px 35px 0 #010906;
  animation: 2.5s badgeAnimation 0s infinite;

  @include laptop {
    margin-left: 2px;
    border-radius: 12px;
    @include responsiveProperty(width, 465, true);
    @include responsiveProperty(height, 123, true);
  }

  @include ipad {
    padding-bottom: 26.3%;
    width: 99%;
    height: auto;
  }
}

@keyframes badgeAnimation {
  0% {
    transform: rotate(-3.5deg) scale(0.94);
  }

  50% {
    transform: rotate(-3.5deg) scale(1.06);
  }

  100% {
    transform: rotate(-3.5deg) scale(0.94);
  }
}

.badge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// balls

.ball1 {
  transform: rotate(30deg) translateY(var(--transform));
  @include responsiveProperty(width, 135.3);
  @include responsiveProperty(height, 135.3);
  @include responsiveProperty(bottom, 206);
  @include responsiveProperty(left, 122);

  @media (max-width: 1700px) {
    display: none;
  }

  @include laptop {
    display: none;
  }
}

.ball2 {
  transform: rotate(25deg) translateY(var(--transform));
  @include responsiveProperty(width, 238.53);
  @include responsiveProperty(height, 238.53);
  @include responsiveProperty(top, -16);
  @include responsiveProperty(right, 48.2);

  @include laptop {
    display: none;
  }
}

.balls {
  @media (min-width: 769px) {
    display: none;
  }
}

.ball3 {
  transform: rotate(24deg) translateY(var(--transform));
  @include responsiveProperty(width, 394);
  @include responsiveProperty(height, 394);
  @include responsiveProperty(top, 54);
  @include responsiveProperty(right, 338);
}

.ball4 {
  transform: rotate(-30deg) translateY(var(--transform));
  @include responsiveProperty(width, 430);
  @include responsiveProperty(height, 430);
  @include responsiveProperty(top, 287);
  @include responsiveProperty(left, 328);
}

.ball5 {
  top: 308px;
  transform: rotate(25deg) translateY(var(--transform));
  @include responsiveProperty(width, 765);
  @include responsiveProperty(height, 765);
  @include responsiveProperty(left, 285);
}

.ball6 {
  top: 482px;
  transform: rotate(-30deg) translateY(var(--transform));
  @include responsiveProperty(width, 430);
  @include responsiveProperty(height, 430);
  @include responsiveProperty(right, -155);
}
