.list {
  --item-size: 48px;
  --icon-size: 24px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--item-size);
  height: var(--item-size);
  color: #0b0c1e;
  background-color: var(--stroce-defoult);
  border-radius: 100%;
  transition:
    background-color 0.4s,
    color 0.4s;

  &:hover {
    color: #fed748;
    background-color: var(--text-main);
  }

  svg {
    display: block;
    width: var(--icon-size);
    height: var(--icon-size);
  }
}
