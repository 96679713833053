@import '@/shared/scss-utils/breakpoints';

.logo {
  display: block;
  position: absolute;
  top: 50%;
  left: -36px;
  bottom: 0;
  transform: translateY(-50%) scale(0.8);
  width: 238.5px;
  height: 143px;

  @include mobile {
    left: -26px;
    width: 196px;
    height: 132px;
  }
}

.img {
  display: block;
  width: 100%;
  height: 100%;
}
