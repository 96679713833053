@import '@/shared/scss-utils/functions';
@import '@/shared/scss-utils/breakpoints';

.root {
  position: relative;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  padding-top: 72px;
  padding-bottom: 72px;

  @include ipad {
    padding-top: 44px;
    padding-bottom: 0;
  }
}

.title {
  max-width: 75%;
  margin: 0 auto 64px;
  text-align: center;

  @include laptop {
    font-size: 32px;
    max-width: 50%;
    margin-bottom: 41px;
  }

  @include ipad {
    font-size: 24px;
    max-width: 90%;
    margin-bottom: 30px;
  }
}

.icon {
  width: 95px;
  height: 71px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 4px 5px 20px 0 var(--text-main);

  @include laptop {
    width: 48px;
    height: 36px;
    border-radius: 2px;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @include laptop {
    justify-content: center;
    gap: 46px;
  }

  @include ipad {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 23px;
    margin: 0 -16px;
    padding: 0 16px;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background: transparent;
    }
  }
}

.item {
  display: flex;
  align-items: center;
}

.text {
  font-size: 32px;
  line-height: 110%;
  text-transform: uppercase;
  margin-left: 16px;

  @include laptop {
    font-size: 24px;
    margin-left: 10px;
  }
}

.ball1 {
  transform: rotate(25deg) translateY(var(--transform));
  @include responsiveProperty(width, 175.85);
  @include responsiveProperty(height, 175.85);
  @include responsiveProperty(bottom, -210);
  @include responsiveProperty(left, 278);

  @include laptop {
    opacity: 0.2;
    @include responsiveProperty(width, 350, true);
    @include responsiveProperty(height, 350, true);
    @include responsiveProperty(bottom, -180, true);
    @include responsiveProperty(left, -130, true);
  }

  @include ipad {
    opacity: 1;
    transform: rotate(-30deg) translateY(var(--transform));
    width: 64px;
    height: 64px;
    top: 35px;
    left: -25px;
  }
}
