@import '@/shared/scss-utils/breakpoints';

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  max-width: 295px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--text-white);
  line-height: 120%;
  border-radius: 4px;
  padding: 20px 24px;
  transition: color 0.3s;

  &[hidden] {
    display: none;
  }

  &:before {
    background: linear-gradient(
      97deg,
      #348e48 0%,
      #37b944 48.96%,
      #348e48 100%
    );
  }

  @include desktop {
    &:hover {
      color: var(--text-main);

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }

    &:active {
      &:after,
      &:before {
        opacity: 0;
      }

      .bg {
        opacity: 1;
      }
    }

    &:after {
      opacity: 0;
      background: linear-gradient(
        97deg,
        #febd17 0%,
        #ffec43 48.96%,
        #ffe143 100%
      );
    }
  }
}

.bg,
.btn:before,
.btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: opacity var(--transition-duration);
}

.bg {
  opacity: 0;
  background: linear-gradient(97deg, #0f3bcb 0%, #194df4 100%);
}
