*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

body {
  position: relative;
  min-width: var(--min-width);
  min-height: 100%;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-white);
  background-image: url('../../../public/images/bg-img.jpg');

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#154227, 43%);
  }
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  border: none;
  padding: 0;
  font-family: inherit;
  background: transparent;
  cursor: pointer;
}

a {
  text-decoration: none;
}

#root {
  position: relative;
  z-index: 2;
  overflow: hidden;
  margin: 0 auto;
}
