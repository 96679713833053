@import '@/shared/scss-utils/functions';
@import '@/shared/scss-utils/breakpoints';

.root {
  position: relative;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  padding-top: 147px;
  padding-bottom: 72px;
  text-align: center;

  @include laptop {
    padding-top: 161px;
  }

  @include tablet {
    padding-top: 100px;
  }

  @include mobile {
    padding-top: 11px;
    padding-bottom: 0;
  }
}

.title {
  margin-bottom: 17px;

  @include laptop {
    font-size: 32px;
  }

  @include mobile {
    font-size: 24px;
    margin-bottom: 12px;
  }
}

.desc {
  min-height: 70px;

  @include mobile {
    font-size: 16px;
    min-height: 80px;
  }
}

.carousel {
  position: relative;
  display: flex;
  align-items: center;
  gap: 32px;
  overflow: hidden;
  margin: 0 auto;

  @include ipad {
    gap: 18px;
    flex-direction: column;
  }
}

.btns {
  --offset-x: 11.3%;
  --offset-y: 35px;
  position: absolute;
  z-index: 3;
  top: calc(50% + var(--offset-y));
  left: var(--offset-x);
  right: var(--offset-x);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include laptop {
    --offset-x: 4.7%;
    --offset-y: 30px;
  }

  @include ipad {
    position: static;
    transform: none;
    justify-content: center;
    gap: 16px;
  }
}

.carouselWrapper {
  position: relative;
  z-index: 4;
  max-width: 64%;
  width: 100%;
  margin: 0 auto;

  @include tablet {
    max-width: 68%;
  }

  @include ipad {
    max-width: 100%;
  }
}

.carouselViewport {
  overflow: hidden;
}

.carouselContainer {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2px;
}

.slide {
  flex: 0 0 100%;
  min-width: 0;
}

.slideContainer {
  position: relative;
}

.imageContainer {
  position: relative;
  width: 100%;
  padding-bottom: 58.5%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
}

.mainImage {
  top: 2.4%;
  left: 1.7%;
  width: calc(100% - 3.2%);
  height: calc(100% - 7%);
}

.prev,
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  flex-shrink: 0;
  height: 64px;
  border-radius: 8px;
  color: #2a3156;
  background-color: #f4f9ff;

  @include ipad {
    width: 50px;
    height: 50px;
  }

  @include mobile {
    width: 40px;
    height: 40px;
  }
}

.arrow {
  display: block;
  width: 24px;
  height: 24px;
  transform: rotate(90deg);

  @include ipad {
    width: 20px;
    height: 20px;
  }

  @include mobile {
    width: 16px;
    height: 16px;
  }
}

.next .arrow {
  transform: rotate(-90deg);
}

.ball1 {
  z-index: 4;
  top: 246px;
  transform: translateY(var(--transform));
  @include responsiveProperty(width, 135.3);
  @include responsiveProperty(height, 135.3);
  @include responsiveProperty(left, 188.5);

  @include laptop {
    top: auto;
    @include responsiveProperty(bottom, -153, true);
    @include responsiveProperty(width, 180, true);
    @include responsiveProperty(height, 180, true);
    @include responsiveProperty(left, 249, true);
  }

  @include ipad {
    display: none;
  }
}

.ball2 {
  opacity: 0.2;
  transform: rotate(25deg) translateY(var(--transform));
  @include responsiveProperty(width, 238.53);
  @include responsiveProperty(height, 238.53);
  @include responsiveProperty(top, -25);
  @include responsiveProperty(right, 68);

  @include laptop {
    display: none;
  }

  @include ipad {
    display: block;
    top: auto;
    @include responsiveProperty(bottom, -535, true);
    @include responsiveProperty(left, -230, true);
    @include responsiveProperty(width, 500, true);
    @include responsiveProperty(height, 500, true);
  }
}

.ball3 {
  display: none;

  @include laptop {
    display: block;
    z-index: 5;
    transform: rotate(30deg) translateY(var(--transform));
    top: 275px;
    @include responsiveProperty(width, 182, true);
    @include responsiveProperty(height, 182, true);
    @include responsiveProperty(left, 205, true);
  }
}

.confetti1 {
  z-index: 3;
  left: 0;
  top: calc(50% + 63px);
  transform: translateY(calc(-50% + var(--transform)));
  @include responsiveProperty(width, 818);
  @include responsiveProperty(height, 782);

  @include laptop {
    top: calc(50% - 20px);
    left: -140px;
  }

  @include ipad {
    left: 0;
    top: calc(50% + 23px);
    @include responsiveProperty(width, 631, true);
    @include responsiveProperty(height, 1857, true);
  }
}

.confetti2 {
  z-index: 3;
  right: 0;
  top: calc(50% + 43px);
  transform: translateY(calc(-50% + var(--transform)));
  @include responsiveProperty(width, 890);
  @include responsiveProperty(height, 795);

  @include laptop {
    top: calc(50% - 65px);
    right: -280px;
  }

  @include ipad {
    right: 0;
    top: calc(50% + 23px);
    @include responsiveProperty(width, 631, true);
    @include responsiveProperty(height, 1857, true);
  }
}

.bg {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(var(--transform));

  @include laptop {
    top: -10px;
  }

  @include mobile {
    top: 0;
  }
}
