@import '@/shared/scss-utils/functions';
@import '@/shared/scss-utils/breakpoints';

.root {
  position: relative;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 72px;
  padding-bottom: 72px;

  @include laptop {
    padding-top: 38px;
    padding-bottom: 0;
    --container-width: 992px;
  }
}

.title {
  max-width: 60%;
  margin: 0 auto 81px;
  text-align: center;

  @include laptop {
    font-size: 32px;
    max-width: 50%;
    margin-bottom: 38px;
  }

  @include ipad {
    max-width: 100%;
    font-size: 24px;
    margin-bottom: 34px;
  }
}

.items {
  display: flex;
  gap: 40px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 40px;

  @include laptop {
    gap: 28px;
    margin-bottom: 30px;
  }

  @include ipad {
    gap: 24px;
    margin-bottom: 34px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  min-height: 468px;
  flex-direction: var(--direction);
  padding: 0 140px;
  border-radius: 16px;
  background-image: url('../../../public/images/launch/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  @include laptop {
    gap: 4px;
    padding: 0 30px;
    min-height: 355px;
  }

  @include ipad {
    flex-direction: column;
    padding: 46px 24px;
    border-radius: 8px;
  }

  @include mobile {
    padding: 26px 24px 21px;
  }
}

.image {
  position: relative;
  display: block;
  flex-shrink: 0;
  width: 55%;
  padding-bottom: 32.2%;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.image-2 {
  width: 57.9%;
  padding-bottom: 32.2%;
  margin-right: 15px;
}

.image,
.image-2 {
  @include ipad {
    width: 100%;
    padding-bottom: 55%;
    margin-bottom: 23px;
  }
}

.image-1 {
  @include ipad {
    padding-bottom: 58%;
  }
}

.text {
  max-width: 454px;
  margin-top: 3px;
}

.itemTitle {
  margin-bottom: 8px;

  @include ipad {
    line-height: 130%;
  }
}

.itemDesc {
  font-size: 18px;
}

.btn {
  @include laptop {
    max-width: 240px;
  }

  @include mobile {
    max-width: 100%;
  }
}

.ball1 {
  transform: rotate(-30deg) translateY(var(--transform));
  @include responsiveProperty(width, 83.69);
  @include responsiveProperty(height, 83.69);
  @include responsiveProperty(top, 261);
  @include responsiveProperty(left, 10);
}

.ball2 {
  transform: rotate(25deg) translateY(var(--transform));
  @include responsiveProperty(width, 238.53);
  @include responsiveProperty(height, 238.53);
  @include responsiveProperty(bottom, -88.5);
  @include responsiveProperty(left, 164);
}

.ball3 {
  transform: rotate(25deg) translateY(var(--transform));
  opacity: 0.2;
  @include responsiveProperty(width, 238.53);
  @include responsiveProperty(height, 238.53);
  @include responsiveProperty(bottom, -42);
  @include responsiveProperty(right, -61);
}

.ball1,
.ball2,
.ball3 {
  @include laptop {
    display: none;
  }
}

.ball4,
.ball5,
.ball6,
.ball7 {
  @media (min-width: 1441px) {
    display: none;
  }
}

.ball4,
.ball5,
.ball6,
.ball7 {
  @include tablet {
    display: none;
  }
}

.ball4 {
  transform: rotate(-31deg) translateY(var(--transform));
  top: 278px;
  @include responsiveProperty(width, 112.5);
  @include responsiveProperty(height, 112.5);
  @include responsiveProperty(left, 109);

  @include mobile {
    display: block;
    transform: rotate(-31deg) translateY(var(--transform));
    top: auto;
    @include responsiveProperty(bottom, -330, true);
    @include responsiveProperty(width, 312, true);
    @include responsiveProperty(height, 312, true);
    @include responsiveProperty(left, -165, true);
  }
}

.ball5 {
  transform: rotate(25deg) translateY(var(--transform));
  top: 466px;
  @include responsiveProperty(width, 194);
  @include responsiveProperty(height, 194);
  @include responsiveProperty(left, 136);
}

.ball6 {
  transform: rotate(25deg) translateY(var(--transform));
  top: 477px;
  @include responsiveProperty(width, 320);
  @include responsiveProperty(height, 320);
  @include responsiveProperty(right, -155);
}

.ball7 {
  transform: rotate(25deg) translateY(var(--transform));
  top: 965px;
  @include responsiveProperty(width, 235);
  @include responsiveProperty(height, 235);
  @include responsiveProperty(left, 348);
}
