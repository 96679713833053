:root {
  // base
  --font-family: 'Arimo', sans-serif;

  --content-width: 1432px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --color-white: #f6f8ff;
  --text-main: #000;

  --text-privary: #f6f8ff;
  --text-secondary: #dbdddf;

  --stroce-defoult: #cacdde;
  --stroce-active: #f6f8ff;

  // other
  --min-width: 320px;
  --transition-duration: 0.2s;
  --radius: 4px;
}
