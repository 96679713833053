@import '@/shared/scss-utils/functions';
@import '@/shared/scss-utils/breakpoints';

.root {
  position: relative;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  padding-top: 16px;

  @include laptop {
    padding-top: 33px;
  }

  @include mobile {
    padding-top: 17px;
  }
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 77.8%;

  @include laptop {
    width: 61%;
  }

  @include tablet {
    width: 80%;
  }

  @include mobile {
    width: 100%;
  }
}

.image {
  display: block;
  border-radius: 4px;
  overflow: hidden;
  margin-left: -4px;
  margin: 0 auto 43px;

  @include laptop {
    margin-bottom: 38px;
  }

  @include mobile {
    margin-bottom: 25.5px;
  }
}

.title {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto 12px;

  @include laptop {
    margin-bottom: 7.5px;
  }

  @include mobile {
    max-width: 100%;
    margin-bottom: 12px;
  }
}

.description {
  position: relative;
  z-index: 2;
  font-size: 44px;
  line-height: 110%;
  text-align: center;
  margin-bottom: 40px;

  @include laptop {
    font-size: 28px;
    margin-bottom: 32px;
  }

  @include mobile {
    font-size: 24px;
    margin-bottom: 24px;
  }
}

.btn {
  justify-content: center;

  @include laptop {
    max-width: 240px;
  }

  @include mobile {
    min-width: 100%;
  }
}

// Balls

.ball1 {
  transform: rotate(29deg) translateY(var(--transform));
  @include responsiveProperty(width, 135.3);
  @include responsiveProperty(height, 135.3);
  @include responsiveProperty(top, 26);
  @include responsiveProperty(left, 135);

  @include laptop {
    @include responsiveProperty(width, 180, true);
    @include responsiveProperty(height, 180, true);
    @include responsiveProperty(top, 35, true);
    @include responsiveProperty(left, 181, true);
  }

  @include mobile {
    display: none;
  }
}

.ball2 {
  transform: rotate(25deg) translateY(var(--transform));
  opacity: 0.2;
  @include responsiveProperty(width, 238.54);
  @include responsiveProperty(height, 238.54);
  @include responsiveProperty(bottom, 135);
  @include responsiveProperty(left, -39);

  @include laptop {
    @include responsiveProperty(width, 320, true);
    @include responsiveProperty(height, 320, true);
    @include responsiveProperty(bottom, -210, true);
    @include responsiveProperty(left, -90, true);
  }

  @include mobile {
    display: none;
  }
}

.ball3 {
  transform: rotate(-25deg) translateY(var(--transform));
  @include responsiveProperty(width, 83.69);
  @include responsiveProperty(height, 83.69);
  @include responsiveProperty(top, 363);
  @include responsiveProperty(left, 426);

  @include laptop {
    transform: rotate(-31deg) translateY(var(--transform));
    @include responsiveProperty(width, 111.5, true);
    @include responsiveProperty(height, 111.5, true);
    @include responsiveProperty(top, 484, true);
    @include responsiveProperty(left, 568.5, true);
  }

  @include mobile {
    display: none;
  }
}

.ball4 {
  transform: rotate(-25deg) translateY(var(--transform));
  @include responsiveProperty(width, 83.69);
  @include responsiveProperty(height, 83.69);
  @include responsiveProperty(top, 715);
  @include responsiveProperty(left, 426);

  @include laptop {
    top: 715px;
    transform: rotate(-31deg) translateY(var(--transform));
    @include responsiveProperty(width, 111.5, true);
    @include responsiveProperty(height, 111.5, true);
    @include responsiveProperty(left, 568.5, true);
  }

  @include mobile {
    display: none;
  }
}

.ball5 {
  transform: rotate(25deg) translateY(var(--transform));
  @include responsiveProperty(width, 175.85);
  @include responsiveProperty(height, 175.85);
  @include responsiveProperty(top, 408);
  @include responsiveProperty(left, 695);

  @include laptop {
    left: auto;
    @include responsiveProperty(width, 200, true);
    @include responsiveProperty(height, 200, true);
    @include responsiveProperty(top, 490, true);
    @include responsiveProperty(right, 178.5, true);
  }

  @include mobile {
    display: none;
  }
}

.ball6 {
  transform: translateY(var(--transform)) translateY(var(--transform));
  @include responsiveProperty(width, 135.3);
  @include responsiveProperty(height, 135.3);
  @include responsiveProperty(top, 191);
  @include responsiveProperty(left, 1006);

  @include laptop {
    left: auto;
    @include responsiveProperty(width, 133, true);
    @include responsiveProperty(height, 133, true);
    @include responsiveProperty(top, 94, true);
    @include responsiveProperty(right, 456, true);
  }

  @include mobile {
    right: auto;
    transform: rotate(15deg) translateY(var(--transform));
    @include responsiveProperty(left, -240, true);
    @include responsiveProperty(width, 355, true);
    @include responsiveProperty(height, 355, true);
    @include responsiveProperty(top, 110, true);
  }
}

.ball7 {
  transform: rotate(25deg) translateY(var(--transform));
  @include responsiveProperty(width, 238.53);
  @include responsiveProperty(height, 238.53);
  @include responsiveProperty(top, 590);
  @include responsiveProperty(right, 242);

  @include laptop {
    top: auto;
    @include responsiveProperty(width, 221.5, true);
    @include responsiveProperty(height, 221.5, true);
    @include responsiveProperty(bottom, -221, true);
    @include responsiveProperty(right, -93, true);
  }

  @include mobile {
    right: -6px;
    bottom: auto;
    @include responsiveProperty(width, 250, true);
    @include responsiveProperty(height, 250, true);
    @include responsiveProperty(top, 925, true);
  }
}

.ball8 {
  transform: rotate(-50deg) translateY(var(--transform));
  @include responsiveProperty(width, 307.3);
  @include responsiveProperty(height, 307.3);
  @include responsiveProperty(top, 165);
  @include responsiveProperty(right, -113);

  @include laptop {
    display: none;
  }
}

// Confetti

.confetti1 {
  top: -80px;
  transform: translateY(var(--transform));
  @include responsiveProperty(width, 315);
  @include responsiveProperty(height, 894);

  @include laptop {
    top: -20px;
    @include responsiveProperty(width, 415, true);
    @include responsiveProperty(height, 899, true);
  }

  @include mobile {
    @include responsiveProperty(top, -350, true);
    @include responsiveProperty(left, -35, true);
    @include responsiveProperty(width, 695, true);
    @include responsiveProperty(height, 2130, true);
  }
}

.confetti2 {
  z-index: -1;
  top: 28px;
  @include responsiveProperty(width, 1462);
  @include responsiveProperty(height, 998);

  @include laptop {
    display: none;
  }
}

.confetti3 {
  right: 0;
  top: -80px;
  transform: translateY(var(--transform));
  @include responsiveProperty(width, 396);
  @include responsiveProperty(height, 894);

  @include laptop {
    top: 70px;
    right: -40px;
    transform: rotate(-20deg) translateY(var(--transform));
    @include responsiveProperty(width, 405, true);
    @include responsiveProperty(height, 904, true);
  }

  @include mobile {
    left: auto;
    transform: rotate(-25deg) translateY(var(--transform));
    @include responsiveProperty(top, -55, true);
    @include responsiveProperty(right, -220, true);
    @include responsiveProperty(width, 300, true);
    @include responsiveProperty(height, 1290, true);
  }
}
