.root {
  position: relative;
}

.current {
  display: flex;
  gap: 4px;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.name {
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-secondary);
}

.arrow {
  display: block;
  width: 16px;
  height: 16px;
  color: var(--text-secondary);
}

.dropdown {
  position: absolute;
  z-index: 10;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px;
  border-radius: 8px;
  background: #1f2430;
  padding: 16px;
  width: 100px;
  right: 0;

  &[hidden] {
    display: none;
  }
}

.bottom {
  bottom: -25px;
  transform: translateY(100%);
}

.top {
  top: -10px;
  transform: translateY(-100%);
}

.dropdownItem {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  column-gap: 4px;
  cursor: pointer;
}
