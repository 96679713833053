.row {
  display: flex;
  gap: 12px;
  align-items: center;
  min-height: 59.2px;

  &[hidden] {
    display: none;
  }
}

.close {
  display: block;
  width: 40px;
  height: 40px;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
