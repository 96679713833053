@import '@/shared/scss-utils/breakpoints';

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-offset);
  padding-right: var(--container-offset);
  max-width: var(--container-width);

  @include laptop {
    --container-width: 1256px;
  }
}
