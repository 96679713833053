@import '@/shared/scss-utils/breakpoints';

.root {
  min-height: 80px;
  z-index: 100;

  @include mobile {
    min-height: 68px;
  }
}

.container {
  position: relative;
  min-height: inherit;

  @include ipad {
    padding: 58px 24px 24px;
    margin-top: -58px;
  }
}

.logo {
  @include ipad {
    top: 0;
    transform: translateY(0) scale(0.8);
  }

  @include mobile {
    left: -30px;
    width: 255px;
    height: 132px;
  }
}

.right {
  display: flex;
  gap: 32px;
  min-height: inherit;
  align-items: center;
  justify-content: flex-end;

  @include ipad {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 50px;
  }

  @include mobile {
    align-items: flex-end;
  }
}

.title {
  font-size: 18px;
  line-height: 130%;
}

.socialContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  @include mobile {
    align-items: flex-start;
    flex-direction: column;
  }
}

.social {
  gap: 8px;
  --item-size: 32px;
  --icon-size: 20px;
}
