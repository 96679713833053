@import '@/shared/scss-utils/breakpoints';

.root {
  position: relative;
  min-height: 80px;
  z-index: 100;

  @include mobile {
    min-height: 68px;
  }
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: inherit;

  @include mobile {
    padding-right: 5px;
  }
}
