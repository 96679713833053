@font-face {
  font-family: 'Arimo';
  src: url('../../../public/fonts/Arimo-Bold.woff2');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Arimo';
  src: url('../../../public/fonts/Arimo-Regular.woff2');
  font-display: swap;
  font-weight: 400;
}
