@import '@/shared/scss-utils/breakpoints';

.root {
  margin-bottom: 80px;

  @include laptop {
    margin-bottom: 48px;
  }

  @include mobile {
    margin-bottom: 40px;
  }
}
