$screen-width: 1920;

@function vw($value) {
  @return calc($value / $screen-width * 100vw);
}

@function convertToPx($value) {
  @return $value + #{px};
}

@function fluid($min, $max, $wMin, $wMax) {
  $result: calc(
    #{$min}px + (#{$max} - #{$min}) *
      ((100vw - #{$wMin}px) / (#{$wMax} - #{$wMin}))
  );

  @return $result;
}

@mixin responsiveProperty($property, $value, $isInQuery: false) {
  @if $isInQuery {
    #{$property}: vw($value);
  } @else {
    #{$property}: convertToPx($value);

    @media (max-width: convertToPx($screen-width)) {
      #{$property}: vw($value);
    }
  }
}
